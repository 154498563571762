import { Target, Value } from "@vytant/stimulus-decorators";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { StripeElementsOptionsMode } from "@stripe/stripe-js/dist/stripe-js/elements-group";
import * as Sentry from "@sentry/browser";
import ApplicationController from "./application_controller";
import ToastController from "./toast_controller";
import Stimulus from "../helpers/stimulus";
import FormController from "./form_controller";

export default class StripeCheckoutFormController extends ApplicationController {
  @Value(String) returnUrlValue!: string;
  @Value(Number) amountCentsValue!: number;
  @Target paymentElementTarget!: HTMLElement;

  stripe!: Stripe;
  stripeElements!: StripeElements;

  async initialize() {
    // Using loadStripe ensures we do not load Stripe on all pages
    const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

    if (stripe != null) {
      this.stripe = stripe;

      const options: StripeElementsOptionsMode = {
        mode: "subscription",
        currency: "usd",
        setupFutureUsage: "off_session",
        // Amount is used for Apple Pay, Google Pay and Pay later
        amount: this.amountCentsValue,
      };
      this.stripeElements = stripe.elements(options);
      const paymentElement = this.stripeElements.create("payment");
      paymentElement.mount(this.paymentElementTarget);
    } else {
      Stimulus.getController(document.body, ToastController)!.alert(
        "There were some errors loading the payment checkout. Please try to reload the page. If this doesn't help, please contact support",
      );
      // We unfortunately do not have any more info at this point, but lets at least log it to Sentry:
      Sentry.captureMessage("Could not load Stripe");
    }
  }

  // handleSubmit is called on submit button press and then
  // 1. Stripe will check the form validity (such as card number validity)
  // 2. Push the form to our backend via XHR
  // Note, we do not push data to Stripe here, that is done in the backend
  // and when we return from the XHR in confirmIntent()
  async handleSubmit(event: StimulusEvent) {
    if (this.stripe == null) {
      Stimulus.getController(document.body, ToastController)!.alert(
        "There were some errors with the payment, please contact support",
      );
      event.preventDefault();
      return;
    }
    // Trigger form validation
    const promise = this.stripeElements.submit().then(({ error }) => {
      if (error) {
        // In this case the Stripe js will mark necessary errors in the form itself
        // We just need to prevent submitting the form
        event.preventDefault();
      }
    });
    event.detail.promises.push(promise);
  }

  // confirmIntent gets called after we return from the backend submit XHR
  // The backend returns here with a secret it got from Stripe which we in turn use to push all the card data to Stripe
  // Stripe will then check card details and do 3DS if necessary, and redirect us back to this.returnUrlValue
  async confirmIntent(event: StimulusEvent) {
    const { clientSecret } = event.detail.data;
    const elements = this.stripeElements;

    // We use a setTimeout to ensure isSubmittingValue sets after any cleanup in form_controller
    // For unknown reasons, Promise.resolve().then() does not work
    setTimeout(async () => {
      this.formController.isSubmittingValue = true;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await this.stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        this.formController.isSubmittingValue = false;
        if (error.message !== undefined) {
          Stimulus.getController(document.body, ToastController)!.alert(error.message);
        }
      } else {
        // We should never this, we will instead be redirected to returnUrlValue
      }
    });
  }

  get formController() {
    return Stimulus.getController(this.element, FormController)!;
  }
}
