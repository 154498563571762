import { Options, SeriesOptionsType } from "highcharts";

function markIsolatedPoints(seriesArray: Array<SeriesOptionsType>) {
  seriesArray.forEach((series) => {
    // @ts-ignore
    const data = series.data as Array<any>;

    for (let i = 0; i < data.length; i += 1) {
      const point = data[i];
      const prevPoint = data[i - 1];
      const nextPoint = data[i + 1];

      // if the point has adjacent gaps in data, we make it visible by giving it a custom marker
      if (point.y && prevPoint?.y == null && nextPoint?.y == null) {
        point.marker = {
          enabled: true,
          radius: 3,
        };
      }
    }
  });
  return seriesArray;
}

export default function lineseriesChartOptions(config: {
  series: Array<Highcharts.SeriesOptionsType>;
  yAxisMin?: number;
  yAxisReversed?: boolean;
}) {
  return {
    chart: {
      type: "line",
    },
    tooltip: {
      formatter(tooltip) {
        const customTooltipLabel = this.points![0].point.options.custom?.tooltipLabel;
        const defaultTooltipLabel = this.points![0].key;
        const header = `<div class='mb-1'><strong>${customTooltipLabel || defaultTooltipLabel}</strong></div>`;
        // private method bodyFormatter
        // @ts-ignore
        return header + tooltip.bodyFormatter(this.points).join("");
      },
    },
    xAxis: {
      // Handle timestamps and date formatting using ApplicationChart instead of Highcharts
      // This makes it easier to control tooltip and x-axis labeling
      type: "category",
      crosshair: {
        width: 2,
      },
      showFirstLabel: true,
      showLastLabel: true,
      labels: {
        overflow: "allow",
        formatter(label) {
          // Ensure that the first and last labels aren't blocked by empty labels
          // and that the start date and end date do not extend past chart boundaries
          if (label.isFirst) {
            return `<tspan text-anchor="start">${this.value.toString()}</tspan>`;
          }
          if (label.isLast) {
            return `<tspan text-anchor="end">${this.value.toString()}</tspan>`;
          }
          return "";
        },
      },
    },
    yAxis: {
      min: config.yAxisMin,
      reversed: config.yAxisReversed,
      tickPositioner() {
        const yMin = this.min;
        const existingTickPositions = this.tickPositions || [];

        if (yMin) {
          return [yMin, ...existingTickPositions.filter((value: number) => value >= yMin)];
        }

        return existingTickPositions;
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
    series: markIsolatedPoints(config.series),
  } as Options;
}
