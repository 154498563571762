import morphdom from "morphdom";
import ApplicationController from "./application_controller";
import { templatizeHtml, traverseElements } from "../helpers/dom";

const morphdomOptions = {
  onBeforeElUpdated(fromNode: Node, toNode: Node) {
    // non-element nodes don't have `hasAttribute` or `matches` functions
    if (fromNode instanceof Element && toNode instanceof Element) {
      const skipAttr = "data-morphdom-skip";
      if (fromNode.hasAttribute(skipAttr) && toNode.hasAttribute(skipAttr)) {
        return false;
      }
      // re-call because morphdom doesn't traverse <template> tag contents
      if (fromNode instanceof HTMLTemplateElement && toNode instanceof HTMLTemplateElement) {
        morphdom(fromNode.content, toNode.content, morphdomOptions);
      }
    }
    return true;
  },
  getNodeKey(node: Node) {
    // ensure Stimulus [data-controller] attr is always replaced and not mutated, since that can lead to unexpected behavior (ie. stuck tooltips)
    if (node instanceof HTMLElement) {
      return [node.id, node.dataset.controller].join("");
    }
    return undefined;
  },
};

function processFrames(html: string) {
  const template = templatizeHtml(html);
  traverseElements(template, (ele: HTMLElement) => {
    const name = ele.dataset.frame;
    if (name) {
      const targetFrames = document.querySelectorAll(`[data-frame="${name}"]`);
      if (targetFrames.length) {
        targetFrames.forEach((frame) => {
          switch (ele.dataset.frameAction) {
            case "replace":
              frame.innerHTML = "";
              frame.append(...ele.children);
              break;
            default:
              morphdom(frame, ele, { ...morphdomOptions, childrenOnly: true });
          }
        });
      }
      // avoid complexities with processing nested frames
      return true;
    }
    return false;
  });
}

export default class FrameController extends ApplicationController {
  process(event: StimulusEvent) {
    const { html } = event.detail;
    processFrames(html);
  }
}
