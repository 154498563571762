import { Placement } from "@floating-ui/dom";
import Stimulus from "../helpers/stimulus";
import { appendStimulusAction } from "../helpers/dom";
import ApplicationController from "./application_controller";
import DropdownMenuController from "./dropdown_menu_controller";

export default class DropdownController extends ApplicationController {
  activeDropdownMenus!: Set<DropdownMenuController>;
  lastToggleEvent?: StimulusEvent;

  initialize() {
    super.initialize();
    this.activeDropdownMenus = new Set();
  }

  connect() {
    // capture ensures that autoClose fires before dropdowns show
    // required so clicks that remotely open dropdowns don't immediately close them
    // e.g. wysiwyg link menu opening link form
    appendStimulusAction(this.element, "click", `${this.identifier}#autoClose:capture`);
    appendStimulusAction(this.element, "dropdown-menu:show", `${this.identifier}#addActiveDropdownMenu`);
    appendStimulusAction(this.element, "dropdown-menu:hide", `${this.identifier}#removeActiveDropdownMenu`);
    appendStimulusAction(this.element, "keydown.esc", `${this.identifier}#hideAll`);
  }

  toggle(event: StimulusEvent<{ placement: Placement; menuId: number }>) {
    event.preventDefault();
    // prevent multiple toggles with a single click (e.g. overlapping highlighted terms)
    if (event === this.lastToggleEvent) return;
    const anchor = event.currentTarget;
    const { placement, menuId } = event.params;
    const dropdownMenuElement = this.propSelector("id", menuId);
    const dropdownMenu = Stimulus.getController(dropdownMenuElement, DropdownMenuController)!;
    if (dropdownMenu.isActive) {
      dropdownMenu.hide();
    } else {
      dropdownMenu.show(anchor, { placement });
    }
    this.lastToggleEvent = event;
  }

  addActiveDropdownMenu(event: StimulusEvent) {
    const dropdownMenu = Stimulus.getController(event.target, DropdownMenuController)!;
    this.activeDropdownMenus.add(dropdownMenu);
  }

  removeActiveDropdownMenu(event: StimulusEvent) {
    const dropdownMenu = Stimulus.getController(event.target, DropdownMenuController)!;
    this.activeDropdownMenus.delete(dropdownMenu);
  }

  autoClose(event: StimulusEvent) {
    this.hideIf(
      (dropdownMenu) => !dropdownMenu.anchor?.contains(event.target) && !dropdownMenu.element.contains(event.target),
    );
  }

  hideAll() {
    this.hideIf(() => true);
  }

  private hideIf(callback: (dropdownMenu: DropdownMenuController) => boolean) {
    this.activeDropdownMenus.forEach((dropdownMenu) => {
      if (callback === undefined || callback(dropdownMenu)) {
        dropdownMenu.hide();
      }
    });
  }
}
