import { Value } from "@vytant/stimulus-decorators";
import ApplicationController from "./application_controller";
import { xhrFetch, XhrFetchMethod } from "../helpers/xhr";

export default class LazyController extends ApplicationController {
  // @ts-ignore
  @Value({ type: Number, default: 0 }) delayValue!: number;
  // @ts-ignore
  @Value({ type: String, default: "get" }) methodValue!: XhrFetchMethod;
  @Value(String) frameValue!: string;
  @Value(String) urlValue!: string;

  connect() {
    this.load();
  }

  load() {
    setTimeout(() => {
      xhrFetch(this.methodValue, this.urlValue, { data: this.params }).then((response) =>
        this.dispatchXhrResponse(response),
      );
    }, this.delayValue);
  }

  get params() {
    return new URLSearchParams({ lazy_frame: this.frameValue });
  }
}
