import ContextError from "./error";
import { appendParamsToUrl } from "./util";

export type XhrFetchMethod = "get" | "post" | "patch" | "delete";
export type XhrFetchData = URLSearchParams | FormData;
export type XhrFetchOptions = { data: XhrFetchData };
export type XhrResponse = Awaited<ReturnType<typeof xhrFetch>>;

export function buildXhrResponse(status: number, headers: Headers, text: string) {
  const contentType = headers.get("content-type") || "";
  if (!(status >= 200 && status <= 399)) {
    throw new ContextError(`xhrFetch error status: ${status}`, { responseBody: text });
  }
  if (status === 309) {
    // custom redirect / refresh status
    let location = headers.get("XHR-Location");
    if (location) {
      window.location.assign(location);
    } else {
      location = window.location.href;
      window.location.reload();
    }
    return { location };
  }
  if (contentType.includes("text/html")) {
    return { html: text, status };
  }
  throw new Error(`Unhandleable XHR response: content-type: ${contentType}, status: ${status}`);
}

export function xhrFetch(
  method: XhrFetchMethod,
  url: string,
  options: XhrFetchOptions = { data: new URLSearchParams() },
) {
  const { data } = options;
  const urlObj = new URL(url, window.location.origin);
  const headers = new Headers();
  // Use default Content-Type (form-urlencoded for xhr, multipart form when body is FormData)
  // https://github.com/github/fetch/issues/505
  headers.set("X-CSRF-Token", document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")!.content);
  headers.set("X-Requested-With", "XMLHttpRequest"); // enables calling `request.xhr?` in Rails
  const fetchOptions: RequestInit = {
    // PATCH must be in all caps
    // https://github.com/github/fetch/issues/254
    method: method.toUpperCase(),
    headers,
    credentials: "same-origin",
  };
  if (fetchOptions.method === "GET") {
    appendParamsToUrl(urlObj, data);
  } else {
    fetchOptions.body = data;
  }

  return fetch(urlObj, fetchOptions).then((response) =>
    response.text().then((text) => buildXhrResponse(response.status, response.headers, text)),
  );
}
